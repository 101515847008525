import * as React from "react"

import PickerScreen from '../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="Are you a designer?"
    options={[
      {
        label: "Yes, I am",
        link: "/app/digital-product-design/hi-fi/what-is-your-goal/"
      },
      {
        label: "No, I am not",
        link: "/app/digital-product-design/hi-fi/I-am-not-a-designer/"
      }
  ]}/>
)

export default Picker;


